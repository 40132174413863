import { BigNumber } from "ethers";
import { configureChains, chain, Chain } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

function increaseGas(fn: Function) {
    // @ts-ignore
    return chain => {
        const cfg = fn(chain)

        const next = {
            // @ts-ignore
            chain: cfg.chain,
            // @ts-ignore
            provider: () => {
                const provider = cfg.provider()
                const buff = provider.send.bind(provider)
                const send = async (method: string, params: any) => {
                    const result = await buff(method, params)
                    if (method === 'eth_estimateGas') {
                        const value = BigNumber.from(result)
                        const increased = value.mul(1.5).toHexString()
                        return increased
                    }
                    return result
                }
                provider.send = send
                return provider
            },
        }

        // @ts-ignore
        if ('webSocketProvider' in cfg) {
            // @ts-ignore
            next.webSocketProvider = cfg.webSocketProvider
        }

        return next
    }
}

export const { chains, provider } =
    configureChains(
        [process.env.NEXT_PUBLIC_CHAIN === 'goerli' ? chain.goerli : chain.mainnet],
        [
            // @ts-ignore
            increaseGas(jsonRpcProvider({
                rpc: chain => {
                    if (chain.id === 1) return { http: 'https://rpc.ankr.com/eth' }
                    if (chain.id === 5) return { http: 'https://rpc.ankr.com/eth_goerli' }
                    throw new Error(`no RPC providers configured for chain id ${chain}`)
                }
            }))
        ]
    )


export function chainName(chain: Chain, isShort: boolean = false) {
    if (chain.id === 1) return (
        <>
            {isShort && ('Ethereum')}
            {!isShort && (
                <b>Ethereum mainnet</b>
            )}
        </>
    )
    return (
        <>
            {isShort && chain.name}
            {!isShort && (
                <>
                    <b>{chain.name}</b> network
                </>
            )}
        </>
    )
}