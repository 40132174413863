import create from "zustand"
// import { fetch } from '@repaya/commons/async'

export interface ConfigState {
    baseUrl: string
    // fetch: (url: string, init: RequestInit) => Promise<Response>
}

export const useConfigStore = create<ConfigState>(() => ({
    baseUrl: '',
    // fetch
}))

export function configure(config: Partial<ConfigState>) {
    useConfigStore.setState(prev => ({ ...prev, ...config }))
}
