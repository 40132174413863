import React, { ComponentType, ReactNode, useMemo } from 'react'
import type { AppProps } from 'next/app'
import { configure as configureAPI } from '../api/config'
import './index.css'
import './fonts.css'
import '@rainbow-me/rainbowkit/styles.css'
import posthog from 'posthog-js'
import Head from 'next/head'
import { chains } from '../web3/chains'
import { useRouter } from 'next/router'
import {  IntlProvider, MessageFormatElement, useIntl } from 'react-intl';
import English from "../../locales/compiled/en.json"

if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_TOKEN!, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST!,
        session_recording: {
            inlineStylesheet: false
        }
    })
}

configureAPI({ baseUrl: process.env.NEXT_PUBLIC_WEB_API_URL })

function Layout({ children }: { children: ReactNode }) {
    return children
}

function LocalizedApp({ children }: { children: ReactNode }) {
    const intl = useIntl()

    return (
        <>
            <Head>
                {chains[0].id !== 1 && (
                    <meta name="robots" content="noindex" />
                )}

                {process.env.NODE_ENV !== 'development' && (
                    <>
                        <script async src="https://www.googletagmanager.com/gtag/js?id=G-P2KR62STF9"></script>
                        <script dangerouslySetInnerHTML={{
                            __html: `
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());

                            gtag('config', 'G-P2KR62STF9');
                        `}}></script>
                    </>
                )}

                <title>
                    {intl.formatMessage({ defaultMessage: 'Web3 payment gateway | repaya.io', id: '0XsAvG' })}
                </title>
                <meta name="description" content={intl.formatMessage({
                    defaultMessage: 'Accept USDT, Ethereum and token payments from your customers directly into your wallet. Share crypto invoices, create donation forms, and receive Ethereum and stablecoins in your online store.',
                    id: 'auce/g',
                    description: 'SEO Meta description'
                })} />

                <link rel='shortcut icon' href='/favicon.ico?v2' />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#333333" />
                <meta name="viewport" content="width=375, initial-scale=0.95, user-scalable=no, minimum-scale=0.95, maximum-scale=0.95" />
                <link rel="icon" href="/favicon.ico?v2" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v2" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v2" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v2" />

                <link rel="manifest" href="/site.webmanifest" />
                <meta name="msapplication-TileColor" content="#da532c" />
                <meta name="theme-color" content="#171717" />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="repaya.io" />
                <meta name="twitter:description" content={''} />
                <meta name="twitter:image" content="/title2.png" />

                <meta property='og:type' content='website' />
                <meta property='og:title' content='repaya.o' />
                <meta property='og:description' content={''} />
                <meta property='og:site_name' content='repaya.io' />
                <meta property='og:url' content={process.env.NEXT_PUBLIC_SELF_URL!} />
                <meta property='og:image' content='/title2.png' />
            </Head>

            {children}
        </>
    )
}

export default function App({ Component, pageProps }: AppProps) {
    const router = useRouter()
    const PageLayout = (Component as unknown as { getLayout: ComponentType<any> }).getLayout || Layout

    const [shortLocale] = router.locale ? router.locale.split("-") : ["en"]

    const messages = useMemo(() => {
        let messages: Record<string, MessageFormatElement[]> = {}
        switch (shortLocale) {
            case "en":
                messages = English
            default:
                messages = English
        }

        messages['brand'] = [{ type: 0, value: process.env.NEXT_PUBLIC_BRAND_NAME }]
        return messages
    }, [shortLocale])


    return (
        <IntlProvider
            locale={shortLocale}
            messages={messages}
            onError={() => null}>

            <LocalizedApp>
                <PageLayout>
                    <Component {...pageProps} />
                </PageLayout>
            </LocalizedApp>
        </IntlProvider>
    )
}

